import { IUserActivityInfo, IUserInfo } from '@webapp-nx-repo/lib-shared-auth';
import { DateTime } from 'luxon';
import { IShip } from './ship';

export interface ICrewNote extends IUserActivityInfo {
	id: number;
	nr: number;
	sub_nr: number | null;
	dt: DateTime;
	master_crew_note_id: number | null;
	mail_sent_at: DateTime | null;
	crew_note_ships?: ICrewNoteShip[];
}
export const EMPTY_CREW_NOTE: ICrewNote = {
	id: 0,
	nr: 0,
	sub_nr: null,
	dt: DateTime.now(),
	master_crew_note_id: null,
	mail_sent_at: null,
};

export interface ICrewNoteShip extends IUserActivityInfo {
	id: number;
	crew_note_id: number;
	ship_id: number;
	ship?: IShip;
	crew_note_see_employees?: ICrewNoteShipSeaEmployee[];
}
export const EMPTY_CREW_NOTE_SHIP: ICrewNoteShip = {
	id: 0,
	crew_note_id: 0,
	ship_id: 0,
};

export interface ICrewNoteShipSeaEmployee extends IUserActivityInfo {
	id: number;
	crew_note_ship_id: number;
	board_qualification_id: number;
	sea_employee_id: number;
	sea_employee?: ISeaEmployee;
	board_qualification?: IBoardQualification;
}
export const EMPTY_CREW_NOTE_SHIP_EMPLOYEE: ICrewNoteShipSeaEmployee = {
	id: 0,
	crew_note_ship_id: 0,
	board_qualification_id: 0,
	sea_employee_id: 0,
};

export interface ISeaEmployee extends IUserActivityInfo {
	id: number;
	user_info_id: number;
	matricola: string;
	compartimento: string;
	user_info?: IUserInfo;
}
export const EMPTY_SEA_EMPLOYEE: ISeaEmployee = {
	id: 0,
	user_info_id: 0,
	matricola: '',
	compartimento: '',
};

export interface IBoardQualification extends IUserActivityInfo {
	id: number;
	name: string;
}

export const EMPTY_BOARD_QUALIFICATION: IBoardQualification = {
	id: 0,
	name: '',
};
